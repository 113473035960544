import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../app/AuthContext';
import { useAuth0 } from '@auth0/auth0-react';

export function useGenericApiRequest({ url, method = 'GET', params = {} }) {
  const { authContext } = useContext(AuthContext);
  const { isLoading } = useAuth0();

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const defaultHeaders = {
    'Content-type': 'application/json; charset=UTF-8',
  };

  const authHeaders = token => {
    if (authContext.token || token) {
      return {
        ['authorization']: `Bearer ${token || authContext.token}`,
      };
    }
    return {};
  };

  const executeRequest = useCallback(
    ({ id, token } = {}) => async data => {
      setLoading(true);
      try {
        const result = await axios({
          url: `${url}/${id ?? ''}`,
          method,
          headers: { ...defaultHeaders, ...authHeaders(token) },
          params,
          data,
        });
        setResponse(result?.data);
        if (result?.data?.message === 'Token is not valid') {
          localStorage.removeItem('token');
          localStorage.removeItem('skipGoalForNow');
          localStorage.removeItem('dismissedAutoHowToPopup');
        }
      } catch (err) {
        setError(err);
        if (process.env.REACT_APP_VERBOSE === 'true') console.error(err);
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    },
    [url, method, defaultHeaders, authHeaders, params],
  );

  return {
    response,
    error,
    loading,
    executeRequest,
    auth0Loading: isLoading,
    loaded,
  };
}
