import { useContext, useEffect, useMemo } from 'react';
import { useGenericApiRequest } from '../common/hooks/useGenericApiRequest';
import _ from 'lodash';
import OneSignal from 'react-onesignal';
import { fromApi } from '../common/models/user';
import { AuthContext } from '../app/AuthContext';

function useFetchCurrentUserProfile() {
  const {
    response,
    auth0Loading,
    loading,
    executeRequest,
    loaded,
  } = useGenericApiRequest({
    url: `${process.env.REACT_APP_PUBLIC_API_URL}/users/profile`,
    method: 'GET',
  });
  const { authContext } = useContext(AuthContext);

  useEffect(() => {
    if (
      !loaded &&
      !loading &&
      _.isEmpty(response) &&
      !auth0Loading &&
      authContext.token
    ) {
      if (authContext.token) {
        executeRequest({ token: authContext.token })();
      }
    }
  }, [response, auth0Loading, executeRequest, authContext.token]);

  const userInfo = useMemo(() => {
    if (response?.uuid) {
      OneSignal.login(response.uuid);
    }
    return fromApi(response ?? {});
  }, [response]);

  return { userInfo, loading, loaded: !!userInfo?.uuid };
}

export default useFetchCurrentUserProfile;
