import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { AuthContext } from '../app/AuthContext';
import useFetchCurrentUserProfile from './useFetchCurrentUserProfile';
import { OverlayComponent } from '../common/OverlayComponent';
import PrimaryButton from './PrimaryButton';
import { Header46, Header60, Subheader22 } from '../common/Typography';
import { BACKGROUNDS } from './constants';
import { ImageCarousel } from '../common/ImageCarousel';
import FORMAT_SIZES from '../common/constants/FormatSizes';
import { useGetFormat } from '../app/useGetFormat';
import { useAuth0 } from '@auth0/auth0-react';
import { FullscreenLoader } from '../common/FullscreenLoader';

const Heading = styled.div`
  h1,
  h2 {
    color: ${({ color }) => color ?? '#fff'} !important;
  }

  h1 {
    line-height: 1.1 !important;
  }

  h2 {
    margin-top: ${({ isMobile }) => (isMobile ? 1.2 : 2)}rem;
    margin-bottom: ${({ isMobile }) => (isMobile ? 2 : 2.5)}rem;
  }
  width: 100%;
  max-width: ${({ width }) => width ?? '420px'};
`;

const HeadingWrapper = styled(Grid)`
  ${({ isMobile }) =>
    !isMobile &&
    `
    margin-left: 12rem !important;
  `}

  ${({ isMobile }) =>
    isMobile &&
    `
    margin: 1rem !important;
    text-align: center !important;
  `}
`;

const PageContainer = styled(Grid)`
  width: unset !important;
`;

const Home = () => {
  const { authContext } = useContext(AuthContext);
  const defaultValue = authContext.token !== null;
  const [secureAuth, setSecureAuth] = useState(defaultValue);
  const [redirectLink, setRedirectLink] = useState('/search');
  const format = useGetFormat();
  const isMobile = format === FORMAT_SIZES.MOBILE;
  const { loading } = useFetchCurrentUserProfile();
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  if (isAuthenticated) {
    getAccessTokenSilently().then(token => {
      localStorage.setItem('token', token);
      setRedirectLink('/search');
    });
  }

  if (secureAuth) {
    authContext.userData(authContext.token).then(data => {
      if (data !== null) {
        setSecureAuth(data?.success);
        setRedirectLink('/search');
      }
    });
  }

  const getRedirectLink = useCallback(() => {
    window.location = redirectLink;
  }, [redirectLink]);

  if (loading) {
    return <FullscreenLoader />;
  }

  return (
    <ImageCarousel
      indicators={false}
      interval={8000}
      stopAutoPlayOnHover={true}
    >
      {BACKGROUNDS.map(background => {
        return (
          <OverlayComponent background={background.background}>
            <PageContainer container justifyContent="center">
              <HeadingWrapper item md={12} isMobile={isMobile}>
                <Heading
                  color={background.color}
                  width={background.maxWidth}
                  isMobile={isMobile}
                >
                  {!isMobile && (
                    <>
                      <Header60 component="h1">{background.header}</Header60>
                      <Subheader22 component="h2">
                        Your trades. Your rules.
                      </Subheader22>
                    </>
                  )}
                  {isMobile && (
                    <>
                      <Header46 component="h1">{background.header}</Header46>
                      <Subheader22 component="h2">
                        Your trades. Your rules.
                      </Subheader22>
                    </>
                  )}
                  <PrimaryButton variant="contained" onClick={getRedirectLink}>
                    Suap Now
                  </PrimaryButton>
                </Heading>
              </HeadingWrapper>
            </PageContainer>
          </OverlayComponent>
        );
      })}
    </ImageCarousel>
  );
};

Home.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};
export default Home;
