export const HOW_CAN_WE_HELP = 'HOW_CAN_WE_HELP';
export const CREATIVE_PROJECTS = 'CREATIVE_PROJECTS';
export const BOOST_MY_PROJECT = 'BOOST_MY_PROJECT';
export const SAVE_MONEY = 'SAVE_MONEY';
export const BUSINESS_MENTORSHIP = 'BUSINESS_MENTORSHIP';
export const FINANCIAL_ADVISE = 'FINANCIAL_ADVISE';
export const WEBSITE_OF_DREAMS = 'WEBSITE_OF_DREAMS';
export const SOCIAL_MEDIA_STRATEGY = 'SOCIAL_MEDIA_STRATEGY';
export const MEDIA_CONTENT = 'MEDIA_CONTENT';
export const CREW_SUPPORT = 'CREW_SUPPORT';
export const EMAIL_MARKETING = 'EMAIL_MARKETING';
export const RIDES = 'RIDES';
export const CREATIVE_ALLY = 'CREATIVE_ALLY';
export const STUDIO_TIME = 'STUDIO_TIME';
export const SOMETHING_ELSE = 'SOMETHING_ELSE';

export const SUPPORT_MY_BUSINESS = 'SUPPORT_MY_BUSINESS';
export const PROFESSIONAL_DEVELOPMENT = 'PROFESSIONAL_DEVELOPMENT';
export const WELLNESS_AND_FITNESS = 'WELLNESS_AND_FITNESS';
export const FOOD = 'FOOD';
export const HAIR_AND_BEAUTY = 'HAIR_AND_BEAUTY';
export const HOME_IMPROVEMENT = 'HOME_IMPROVEMENT';
export const SITTING = 'SITTING';
export const NONE_OF_THESE = 'NONE_OF_THESE';

export const GoalStepLabels = {
  [CREATIVE_PROJECTS]: 'I want help with my creative projects',
  [BOOST_MY_PROJECT]: 'I want help to launch or boost my business',
  [SAVE_MONEY]: 'I want to save money in general',
};

export const GoalOptionSaveMoneyLabels = {
  [SUPPORT_MY_BUSINESS]: 'Support for my business',
  [PROFESSIONAL_DEVELOPMENT]: 'Professional development',
  [WELLNESS_AND_FITNESS]: 'Wellness and fitness',
  [FOOD]: 'Food',
  [HAIR_AND_BEAUTY]: 'Hair and beauty',
  [HOME_IMPROVEMENT]: 'House improvements / Cleaning services',
  [SITTING]: 'Babysitting / Pet sitting / House sitting',
  [SOMETHING_ELSE]: 'Something else in mind? Add it here',
};

export const GoalOptionLabels = {
  [BUSINESS_MENTORSHIP]: 'Business mentorship',
  [FINANCIAL_ADVISE]: 'Financial advice',
  [WEBSITE_OF_DREAMS]: 'Website of my dreams',
  [SOCIAL_MEDIA_STRATEGY]: 'The right social media strategy',
  [MEDIA_CONTENT]: 'Engaging media content',
  [CREW_SUPPORT]: 'Crew support or assistance',
  [EMAIL_MARKETING]: 'Email marketing',
  [RIDES]: 'Rides',
  [CREATIVE_ALLY]: 'Creative ally',
  [STUDIO_TIME]: 'Studio time or audio engineering',
  [SOMETHING_ELSE]: 'Something else in mind? Add it here',
  [NONE_OF_THESE]: 'None of these above',
};
