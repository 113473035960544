import React from 'react';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const SecondaryBtn = styled(Button)`
  text-transform: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
  ${({ condensed }) =>
    !condensed &&
    `
  font-size: 1.15rem !important;
  height: 52px !important;
`}
  margin: 22px 0 !important;
  color: #fff !important;
  padding: 4px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? '#A7ADAF'} !important;
`;

export function SecondaryButton({
  children,
  disabled,
  loading,
  onClick,
  color,
  condensed,
}) {
  return (
    <SecondaryBtn
      type="submit"
      variant="contained"
      color="primary"
      condensed={condensed}
      backgroundColor={loading || disabled ? undefined : color}
      disabled={disabled || loading}
      onClick={onClick}
      fullWidth
    >
      {loading ? <CircularProgress color="inherit" size={30} /> : children}
    </SecondaryBtn>
  );
}
