/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';
import useFetchCurrentUserProfile from '../home/useFetchCurrentUserProfile';
import { isUserProfileCompleted } from '../common/isUserProfileCompleted';
import { HowToSuapModal } from '../common/HowToSuapModal';
import { SecureFooter } from './Footer/SecureFooter';
import { isUserProfileActive } from '../common/isUserProfileActive';
import { FullscreenLoader } from '../common/FullscreenLoader';
import FORMAT_SIZES from '../common/constants/FormatSizes';
import { useGetFormat } from './useGetFormat';
import ReactGA from 'react-ga4';
import { useAuth0 } from '@auth0/auth0-react';
import { isUserProfileEssentialsCompleted } from '../common/isUserProfileEssentialsCompleted';
import { isTokenNotValid } from './utils';
import useGetUserGoals from './useGetUserGoals';

const BLOCKED_ROUTES = ['chat'];

function isOnBlockedRoute(pathname) {
  return BLOCKED_ROUTES.some(route => pathname.includes(route));
}

const SecureRoute = ({
  component: Component,
  openToPublic,
  pageTitle,
  ...rest
}) => {
  const { authContext, setIsProfileCompleted, setIsProfileActive } = useContext(
    AuthContext,
  );
  const history = useHistory();
  const defaultValue =
    authContext.token !== null && !isTokenNotValid(authContext.token);
  const [secureAuth, setSecureAuth] = useState(defaultValue);
  const [howToOpen, setHowToOpen] = useState(false);
  const format = useGetFormat();
  const isMobile = format === FORMAT_SIZES.MOBILE;

  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: isAuthLoading,
  } = useAuth0();

  useEffect(() => {
    if (
      window.location?.search &&
      window.location?.search.includes('message')
    ) {
      history.replace(window.location.pathname);
    }
    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: pageTitle,
    });
  }, [window.location.pathname]);

  useEffect(() => {
    if (!isAuthLoading) {
      if (isAuthenticated && !authContext.token) {
        getAccessTokenSilently().then(token => {
          if (token) {
            localStorage.setItem('token', token);
            authContext.setAuthToken(token);
            authContext.setIsAuth0Authenticated(true);
            setSecureAuth(true);
          }
        });
      } else if (secureAuth) {
        authContext.userData(authContext.token).then(data => {
          if (data) {
            setSecureAuth(data.success);
          } else if (!openToPublic) {
            window.location = '/login';
          }
        });
      } else if (authContext.token && isTokenNotValid(authContext.token)) {
        localStorage.removeItem('token');
        if (!openToPublic) {
          window.location = '/login';
        }
      }
    }
  }, [isAuthLoading, authContext.token]);

  const { userInfo, loaded, loading } = useFetchCurrentUserProfile();
  const { userGoals, loading: loadingGoals } = useGetUserGoals(userInfo.uuid);

  const isProfileCompleted = useMemo(() => {
    const completed = userInfo?.uuid && isUserProfileCompleted(userInfo);
    setIsProfileCompleted(completed);
    return completed;
  }, [userInfo.uuid]);

  const isProfileEssentialsCompleted = useMemo(() => {
    return userInfo?.uuid && isUserProfileEssentialsCompleted(userInfo);
  }, [userInfo.uuid]);

  const isProfileActive = useMemo(() => {
    const completed = userInfo?.uuid && isUserProfileActive(userInfo);
    setIsProfileActive(completed);
    return completed;
  }, [userInfo.uuid]);

  if (loading || loadingGoals || isAuthLoading) {
    return <FullscreenLoader />;
  }

  if (
    userInfo?.uuid &&
    (!(userGoals || localStorage.getItem('skipGoalForNow')) ||
      !isProfileEssentialsCompleted) &&
    window.location?.pathname !== '/signup'
  ) {
    history.push('/signup');
  }

  // TODO - Improve condition to include profile path
  if (
    userInfo?.uuid &&
    !isProfileCompleted &&
    (isOnBlockedRoute(window.location?.pathname) ||
      window.location?.pathname === '/profile')
  ) {
    history.push('/profile/edit');
  }

  const autoOpenHowTo = () => {
    setHowToOpen(true);
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (secureAuth || openToPublic) {
          return (
            <>
              <div>
                <Component
                  isProfileCompleted={isProfileCompleted}
                  isProfileActive={isProfileActive}
                  profileLoading={loading}
                  userInfo={userInfo}
                  loaded={loaded}
                  format={format}
                  autoOpenHowTo={autoOpenHowTo}
                  {...props}
                  {...rest}
                />
              </div>
              {window.location.pathname !== '/signup' && (
                <SecureFooter userInfo={userInfo} isMobile={isMobile} />
              )}
              {!isProfileActive &&
                !['/signup', '/search'].includes(window.location.pathname) && (
                  <HowToSuapModal
                    isOpen={howToOpen}
                    setOpen={setHowToOpen}
                    position={isMobile ? 'fixedMobile' : 'fixed'}
                    isMobile={isMobile}
                  />
                )}
            </>
          );
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

SecureRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default SecureRoute;
