import React, { useState } from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import { Field } from '../styledComponents';
import { Close } from '@mui/icons-material';
import { Avatar, Box } from '@mui/material';
import Placeholder from '../../../assets/images/placeholderAvatar.svg';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import ActionMenuModal from '../../common/Mobile/ActionMenuModal';

const ActiveHeaderContainer = styled.div`
  display: ${({ ismobile }) => (ismobile === 'true' ? 'block' : 'grid')};
  grid-template-columns: 1fr 1fr 1fr 1fr 1.3fr 0.7fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  width: 100%;
  grid-template-areas: 'ActiveHeader ActiveHeader ActiveHeader ActiveHeader ActiveHeader . .';
`;

const ActiveOfferContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.3fr 0.7fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  margin-bottom: 0.5rem;
  width: 100%;
  grid-template-areas: 'ActiveSuap ActiveSuap ActiveSuap ActiveSuap ActiveSuap Menu Menu';
`;

const ActiveOffer = styled.div`
  grid-area: ActiveSuap;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SuapUser = styled(Avatar)`
  height: ${({ isMobile }) => (isMobile ? 40 : 32)}px !important;
  width: ${({ isMobile }) => (isMobile ? 40 : 32)}px !important;
  border: 1px solid #4e5152 !important;
  cursor: pointer !important;
`;

const RelationIcon = styled(Close)`
  margin: 0 4px !important;
  fill: #aab1a9 !important;
  font-size: 14px !important;
`;

const MinimalLink = styled(Link)`
  margin: 0 2px !important;
  color: inherit !important;
  text-decoration: unset !important;
  align-self: center !important;
  font-size: 0.7rem !important;
`;

const ActionMenu = styled.div`
  grid-area: Menu;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8px;
  * {
    color: ${THEME_COLORS.primary} !important;
  }
  a:hover {
    color: ${THEME_COLORS.primary};
  }
`;

const ActiveOfferHeader = styled.div`
  grid-area: ActiveHeader;
  text-align: center;
  background-color: ${THEME_COLORS.primary};
  border-radius: 28px;
  padding: 4px;
  color: #fff;
  margin: 48px 0 16px 0;
  ${({ ismobile }) =>
    ismobile === 'true' &&
    `
      border-radius: 4px;
      margin: 4px 0 8px 0;
  `}
`;

const MobileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
`;

const MenuContainer = styled(IconButton)`
  padding: 0;
`;

const Links = styled.div`
  margin-left: 0.5rem;
  color: ${THEME_COLORS.primary} !important;
`;

function getMenuActions(history, assetId, serialId) {
  return [
    {
      label: 'Details',
      action: () => history.push(`/assets/${assetId}`),
    },
    {
      label: 'Chat',
      action: () => history.push(`/chat/${serialId}`),
    },
    {
      label: 'Help',
      action: () => history.push('/contact-us'),
    },
  ];
}

function ActiveSuapRow({
  isMobile,
  history,
  requestedUserContract,
  requesterUserContract,
  userId,
  serialId,
  onOpenMenuAction,
}) {
  const otherUserContract =
    requestedUserContract.userId === userId
      ? requesterUserContract
      : requestedUserContract;
  const requestedAsset = requestedUserContract.asset;
  const requesterAsset = requesterUserContract.asset;

  if (isMobile) {
    return (
      <MobileRow>
        <div style={{ width: '100%' }}>
          {requestedAsset?.title ? (
            <Field ismobile="true">{requestedAsset?.title}</Field>
          ) : (
            <Field ismobile="true"> -- </Field>
          )}
          {requesterAsset?.title ? (
            <Field ismobile="true">{requesterAsset?.title}</Field>
          ) : (
            <Field ismobile="true"> -- </Field>
          )}
        </div>
        <ActionMenu>
          <SuapUser
            src={otherUserContract.userProfilePicture || Placeholder}
            onClick={() =>
              history.push(`/profile/${otherUserContract.userUuid}`)
            }
            isMobile={true}
          />
          <MenuContainer
            onClick={() =>
              onOpenMenuAction(
                getMenuActions(history, otherUserContract.asset.uuid, serialId),
              )
            }
            type="button"
            aria-label="active-menu-action"
          >
            <MoreVertIcon sx={{ fill: `${THEME_COLORS.primary} !important` }} />
          </MenuContainer>
        </ActionMenu>
      </MobileRow>
    );
  }

  return (
    <ActiveOfferContainer>
      <ActiveOffer>
        {requestedAsset?.title ? (
          <Field>{requestedAsset?.title}</Field>
        ) : (
          <Field> -- </Field>
        )}
        <RelationIcon fontSize="medium" />
        {requesterAsset?.title ? (
          <Field>{requesterAsset?.title} </Field>
        ) : (
          <Field> -- </Field>
        )}
      </ActiveOffer>
      <ActionMenu>
        <SuapUser
          src={otherUserContract.userProfilePicture || Placeholder}
          onClick={() => history.push(`/profile/${otherUserContract.userUuid}`)}
        />
        <Links>
          <MinimalLink href={`/assets/${otherUserContract.asset.uuid}`}>
            Details
          </MinimalLink>{' '}
          |<MinimalLink href={`/chat/${serialId}`}>Chat</MinimalLink> |
          <MinimalLink href="/contact-us">Help</MinimalLink>
        </Links>
      </ActionMenu>
    </ActiveOfferContainer>
  );
}

export function ActiveOfferList({ isMobile, contractDetails, user = {} }) {
  const history = useHistory();
  const Component = isMobile ? Stack : Box;
  const [openModal, setOpenModal] = useState(false);
  const [menuActions, setMenuActions] = useState([]);

  const handleOpenMenuAction = options => {
    setMenuActions(options);
    setOpenModal(true);
  };

  return (
    <div>
      <ActiveHeaderContainer ismobile={isMobile?.toString()}>
        <ActiveOfferHeader ismobile={isMobile?.toString()}>
          Active suaps
        </ActiveOfferHeader>
      </ActiveHeaderContainer>
      <Component divider={<Divider flexItem />} spacing={2}>
        {(contractDetails ?? []).map(contract => (
          <ActiveSuapRow
            key={`active-suap-requested-${contract.requestedUserContract.id}-requester-${contract.requesterUserContract.id}`}
            requestedUserContract={contract.requestedUserContract}
            requesterUserContract={contract.requesterUserContract}
            serialId={contract.serialId}
            userId={user.id}
            history={history}
            isMobile={isMobile}
            onOpenMenuAction={handleOpenMenuAction}
          />
        ))}
      </Component>
      {(contractDetails ?? []).length === 0 && 'No active Suaps yet.'}
      {isMobile && openModal && (
        <ActionMenuModal
          options={menuActions}
          onClose={() => {
            setOpenModal(false);
            setMenuActions([]);
          }}
        />
      )}
    </div>
  );
}
