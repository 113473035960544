import { Box, Typography, Tooltip } from '@mui/material';
import LikableIcon from '@mui/icons-material/FavoriteTwoTone';
import LikedIcon from '@mui/icons-material/Favorite';
import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import useFetchReviews from '../../review/useFetchReviews';
import { Ratings } from '../../common/RatingDisplays';
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import Placeholder from '../../../assets/images/placeholderAvatar.svg';

const UserTitle = styled(Typography)`
  font-weight: bold !important;
  font-size: 1rem !important;
  display: block !important;
  height: 28px !important;
  overflow-y: hidden !important;
`;

const Location = styled(Typography)`
  display: flex !important;
  align-items: center !important;
  font-size: 0.7rem !important;
  margin-bottom: 8px !important;
  span {
    display: block;
  }
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
  margin-left: -2px;
`;

const UserCardContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? 250 : 150)}px;

  @media screen and (min-width: 375px) {
    width: 165px;
  }

  @media screen and (min-width: 440px) {
    width: 200px;
  }

  @media screen and (min-width: 768px) {
    width: 238px;
  }

  @media screen and (min-width: 1024px) {
    width: 220px;
  }

  @media screen and (min-width: 1440px) {
    width: 200px;
  }

  padding-top: 0;
  padding-bottom: 12px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`;

const RatingCount = styled(Typography)`
  display: inline-block;
  font-size: 0.775rem;
`;

const UserCardDetails = styled.div`
  height: 94px;
  display: flex;
  padding: 0 6px;
  flex-direction: column;
  justify-content: space-between;
`;

const LikableContainer = styled.div`
  position: relative;
  background-color: #f7f7f7;
  padding: 0 6px;
  margin-bottom: 4px;
`;

const LikeButtonContainer = styled(IconButton)`
  position: absolute;
  right: -16px;
  top: -16px;

  svg {
    fill: #ff6a6b !important;
    ${({ $isLiked }) =>
      !$isLiked &&
      `
        path:first-child {
          opacity: 1;
          fill: white;
        }
    `}
  }
`;

function LikableButton({ onClick, isLiked }) {
  const [likeToggle, setLikeToggle] = useState(!!isLiked);
  return (
    <LikeButtonContainer
      aria-label="like-button"
      onClick={() => setLikeToggle(!likeToggle)}
      $isLiked={likeToggle}
    >
      {likeToggle ? <LikedIcon /> : <LikableIcon />}
    </LikeButtonContainer>
  );
}

function TruncateTitle({ title }) {
  if (title.length > 52) {
    return <Tooltip title={title}>{title.slice(0, 52)}...</Tooltip>;
  }

  return <span>{title}</span>;
}

export default function UserCard({ isMobile, user, history }) {
  const { reviews } = useFetchReviews('user', user?.uuid);

  return (
    <UserCardContainer isMobile={isMobile}>
      <LikableContainer>
        {false && <LikableButton />}
        <img
          alt={`${user.firstname} ${user.surname} profile picture`}
          src={user.profilepicture || Placeholder}
          onClick={() => window.open(`/profile/${user.uuid}`, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      </LikableContainer>
      <UserCardDetails>
        <div>
          <UserTitle
            onClick={() => history.push(`/profile/${user.uuid}`)}
            style={{ cursor: 'pointer' }}
          >
            <TruncateTitle title={`${user.firstname} ${user.surname}`} />
          </UserTitle>
          <Location>
            <LocationIcon fontSize="small" />
            <span>{user.displaylocation || 'No Location'}</span>
          </Location>
          {!!reviews.userReviewAverage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px 0 8px 0',
              }}
            >
              <Ratings
                name="read-only"
                readOnly
                size="small"
                value={reviews.userReviewAverage ?? 0}
              />
              <RatingCount variant="body2">
                ({reviews.userReviewTotal})
              </RatingCount>
            </Box>
          )}
        </div>
      </UserCardDetails>
    </UserCardContainer>
  );
}
