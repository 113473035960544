import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import './App.css';
import Login from '../login';
import { ProfileHome, EditProfile, PublicProfile } from '../profile';
import Home from '../home';
import { AuthProvider } from './AuthContext';
import SecureRoute from './SecureRoute';
import NotLoggedRoute from './NotLoggedRoute';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from '../toaster';
import UserValidation from '../uservalidation';
import ResetForgotPassword from '../ResetforgotPassword';
import PublicUserReviewList from '../profile/review-list-view';
import ForgotPassword from '../forgotpassword';
import Chat from '../chat';
import {
  AssetSearchPage,
  ViewUserAssetDetailPage,
  ViewUserAssetPage,
} from '../assets';
import { ContactUsPage } from '../contactus/ContactUsPage';
import { DiscoveryPage } from '../discovery/DiscoveryPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TermsAndConditions } from '../essentialFlows/signup/TermsAndConditions';
import { isLocalhost } from '../../serviceWorker';
import { EssentialFlow } from '../essentialFlows';

function App() {
  useEffect(() => {
    if (!isLocalhost && window.location.protocol !== 'https:') {
      window.location.replace(window.location.href.replace('http:', 'https:'));
    }
  }, []);

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <Switch>
            <SecureRoute
              path="/signup"
              component={EssentialFlow}
              pageTitle="Sign up"
            />
            <NotLoggedRoute
              path="/login"
              component={Login}
              pageTitle="Log in"
            />
            <NotLoggedRoute
              path="/validation/newaccount/:token"
              component={UserValidation}
              pageTitle="User validation"
            />
            <NotLoggedRoute
              path="/forgotpassword"
              component={ForgotPassword}
              pageTitle="Forgot password"
            />
            <SecureRoute
              path="/terms-and-conditions"
              component={TermsAndConditions}
              pageTitle="Terms and condition"
              openToPublic={true}
            />
            <NotLoggedRoute
              path="/validation/forgotpassword/:token"
              component={ResetForgotPassword}
              pageTitle="Reset password"
            />
            <SecureRoute
              path="/contact-us"
              component={ContactUsPage}
              pageTitle="Contact us"
              openToPublic={true}
            />
            <SecureRoute
              path="/search"
              component={AssetSearchPage}
              pageTitle="Search"
              openToPublic={true}
            />
            <SecureRoute
              exact
              path="/profile"
              component={ProfileHome}
              pageTitle="User profile"
            />
            <SecureRoute
              exact
              path="/profile/edit"
              component={EditProfile}
              pageTitle="Edit profile"
            />
            <SecureRoute
              exact
              path="/profile/:username/reviews"
              component={PublicUserReviewList}
              pageTitle="User reviews"
            />
            <SecureRoute
              path="/profile/:username"
              component={PublicProfile}
              pageTitle="Public profile"
            />
            <SecureRoute
              path="/assets/:id"
              component={ViewUserAssetDetailPage}
              openToPublic={true}
              pageTitle="Asset details"
            />
            <SecureRoute
              path="/suap-wallet"
              component={ViewUserAssetPage}
              pageTitle="Suap wallet"
            />
            <SecureRoute
              path="/user-suap-wallet/:id"
              component={ViewUserAssetPage}
              openToPublic={true}
              pageTitle="User assets"
            />
            <SecureRoute
              exact
              path="/chat"
              component={Chat}
              pageTitle="Chat list"
            />
            <SecureRoute
              path="/chat/:serialId"
              component={Chat}
              pageTitle="Chatroom"
            />
            <SecureRoute
              path="/assets"
              component={ViewUserAssetPage}
              pageTitle="Suap wallet"
            />
            <SecureRoute
              path="/discovery"
              component={DiscoveryPage}
              pageTitle="Discovery"
            />
            <Route path="/" component={Home} pageTitle="Landing page" />
            <Redirect from="/my-assets" to="/suap-wallet" />
            <Redirect from="/user-assets/:id" to="/user-suap-wallet/:id" />
          </Switch>
          <Toaster />
        </Router>
        <ToastContainer hideProgressBar={true} />
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
