import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { fromApi } from '../../common/models/user';
import { useGenericApiRequest } from '../../common/hooks/useGenericApiRequest';

function useUserFetchProfile(userData) {
  const [userInfo, setUserInfo] = useState(fromApi({}));
  const [loaded, setLoaded] = useState(false);

  const {
    response,
    auth0Loading,
    loading,
    executeRequest,
    loaded: respondLoaded,
  } = useGenericApiRequest({
    url: `${process.env.REACT_APP_PUBLIC_API_URL}/users`,
    method: 'GET',
  });

  const fetchProfile = useCallback(
    id => {
      executeRequest({ id })();
    },
    [executeRequest],
  );

  useEffect(() => {
    const dataLoaded = loaded || userInfo.uuid || respondLoaded;
    const dataCanBeLoaded = !dataLoaded && !_.isEmpty(userData);
    const isLoading = auth0Loading || loading;
    if (dataCanBeLoaded && userData.surname) {
      setUserInfo(fromApi(userData));
      setLoaded(true);
    }

    if (!isLoading && !dataLoaded && userData.uuid && !userData.surname) {
      fetchProfile(userData.uuid);
    }
  }, [userInfo, userData, auth0Loading, loading, respondLoaded, fetchProfile]);

  useEffect(() => {
    if (!userInfo.uuid && response && respondLoaded) {
      setUserInfo(fromApi(response[0]));
      setLoaded(true);
    }
  }, [userInfo, response, respondLoaded]);

  return { userInfo, loaded, fetchProfile, setUserInfo, loading };
}

export default useUserFetchProfile;
