import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SecondaryButton } from '../SecondaryButton';
import { THEME_COLORS } from '../../common/colors';

import {
  FormSteps,
  PROFILE_ESSENTIALS,
  TERMS_AND_CONDITIONS,
} from './FormSteps';
import useSignup from './useSignup';
import { PageFlowLayout } from '../PageLayout';

function validateAllFieldsArePresent(user, fields = []) {
  return fields.every(field => user[field]);
}

export function SignupEssentialsForm({
  user,
  setUserInfo,
  loadingUser,
  loaded,
  onBackToGoal,
  onProfileSkip,
}) {
  const { loading: updating, updateProfile } = useSignup();
  const [currentStep, setCurrentStep] = useState(
    FormSteps[user.firstname ? PROFILE_ESSENTIALS : TERMS_AND_CONDITIONS],
  );
  const [confirmedTermAndConditions, setConfirmedTermAndConditions] = useState(
    user.firstname,
  );

  useEffect(() => {
    if (user.firstname && user.surname && user.displaylocation) {
      setCurrentStep(FormSteps[PROFILE_ESSENTIALS]);
      setConfirmedTermAndConditions(true);
    }
  }, [loaded]);

  const handleFieldsChange = useCallback(
    values => {
      setUserInfo({
        ...user,
        ...values,
      });
    },
    [user],
  );

  const handleFieldChange = field => e => {
    handleFieldsChange({ [field]: e.target.value });
  };

  const onContinue = useCallback(() => {
    if (currentStep.key === PROFILE_ESSENTIALS) {
      updateProfile(user);
    } else if (currentStep.nextStep) {
      setCurrentStep(FormSteps[currentStep.nextStep]);
    }
  }, [user]);

  const handleCheckboxChange = useCallback(
    event => {
      event.persist();
      setConfirmedTermAndConditions(event.target.checked);
    },
    [confirmedTermAndConditions],
  );

  const { component: Component } = currentStep;

  const inputs = useMemo(
    () => ({
      ...user,
      hasAgreed: confirmedTermAndConditions,
    }),
    [user, confirmedTermAndConditions],
  );

  const allFieldsArePresent = useMemo(() => {
    return validateAllFieldsArePresent(inputs, currentStep.requiredFields);
  }, [inputs, currentStep]);

  return (
    <PageFlowLayout
      header={currentStep.header}
      subheader={currentStep.subheader}
      loading={loadingUser}
      onSkip={onProfileSkip}
    >
      <>
        <Component
          inputs={inputs}
          handleCheckboxChange={handleCheckboxChange}
          handleFieldChange={handleFieldChange}
          handleFieldsChange={handleFieldsChange}
        />
        <SecondaryButton
          onClick={onContinue}
          loading={updating}
          disabled={!allFieldsArePresent}
          color={THEME_COLORS.primary}
        >
          Continue
        </SecondaryButton>
      </>
    </PageFlowLayout>
  );
}
