import React from 'react';
import { Body10, Header12 } from '../../../common/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { autoCopy } from '../../../common/utils/autoCopy';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded';
import styled from 'styled-components';
import { Popover } from '../../../common/Popover';

const InviteLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 140px;
  width: 300px;
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 4px 4px 14px #515050;
  justify-content: center;
  border-radius: 1.5rem;

  .MuiInputBase-root {
    width: 100%;
    .MuiInputBase-input {
      padding: 10.5px 14px;
      background-color: #e3e5e3;
    }

    button {
      padding: 4px;
      padding-left: 0;
    }
  }
`;

const InviteLink = styled(Popover)`
  .share-content-pop-over {
    transform: translate3d(463px, -139px, 0px) !important;
  }
`;

const InviteHeader = styled.div`
  margin-bottom: 1rem;
`;

export function InviteLinkModal({ inviteLink, copySuccess, setCopySuccess }) {
  return (
    <InviteLinkContainer>
      <InviteHeader>
        <Header12>Invite a friend</Header12>
        <Body10>
          Copy your invite link and share my profile with a friend.
        </Body10>
      </InviteHeader>
      <OutlinedInput
        id="invite-link"
        value={inviteLink}
        disabled={true}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => autoCopy(inviteLink, setCopySuccess)}
              edge="end"
            >
              {copySuccess ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </InviteLinkContainer>
  );
}
