import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';

const SuapDetailContainer = styled.div`
  display: block;
  font-size: 0.83rem;
  background-color: #fff;
  ${({ isMobile }) =>
    !isMobile &&
    `
    margin-top: 12px;
    padding: 12px;
    text-align: center;
  `}
`;

const SuapDetails = styled.span`
  color: ${THEME_COLORS.primary} !important;
  font-style: italic !important;
`;

function sortByLatest(message, message2) {
  if (message.modifiedAtUnix === message2.modifiedAtUnix) return 0;
  return message.modifiedAtUnix < message2.modifiedAtUnix ? 1 : -1;
}

export function RecentSuaps({ isMobile, user, recentSuaps }) {
  const Component = isMobile ? Stack : Box;

  return (
    <Component divider={<Divider flexItem />} spacing={2}>
      {recentSuaps
        .sort(sortByLatest)
        .slice(0, 3)
        .map(suap => {
          const userSuap =
            user.id === suap.requestedUserContract.userId
              ? suap.requestedUserContract
              : suap.requesterUserContract;
          const otherUserSuap =
            user.id === suap.requestedUserContract.userId
              ? suap.requesterUserContract
              : suap.requestedUserContract;
          const { asset: userAsset, quantity: userQuantity } = userSuap;
          const { asset, quantity } = otherUserSuap;
          return (
            <SuapDetailContainer isMobile={isMobile}>
              {user.firstname} just suaped{' '}
              <SuapDetails>
                {userQuantity} {userAsset.title}
              </SuapDetails>{' '}
              for a
              <SuapDetails>
                {' '}
                {quantity} {asset.title}
              </SuapDetails>
            </SuapDetailContainer>
          );
        })}
    </Component>
  );
}
