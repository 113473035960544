import { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import axios from 'axios';

export function usePostComment(setMessage, getComments) {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const [posting, setPosting] = useState(false);

  const createComment = (postId, postType, parentCommentId, comment) => {
    setPosting(true);
    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API_URL}/comments`,
        {
          postUuid: postId,
          postType,
          parentCommentId,
          description: comment,
        },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setPosting(false);
        getComments && getComments();
        setMessage('');
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setPosting(false);
      });
  };

  const editComment = (commentId, comment) => {
    setPosting(true);
    axios
      .put(
        `${process.env.REACT_APP_PUBLIC_API_URL}/comments`,
        {
          commentId,
          description: comment,
        },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setPosting(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setPosting(false);
      });
  };

  return {
    createComment,
    editComment,
    posting,
  };
}
