import React from 'react';
import { PageLayout } from '../components/PageLayout';
import { LeftSection } from './LeftSection';
import { MiddleSection } from './MiddleSection';
import useFetchChats from '../useFetchChats';
import useFetchChatDetails from '../useFetchChatDetails';
import { useHistory } from 'react-router-dom';
import useUserInfo from '../../common/hooks/useUserInfo';
import FORMAT_SIZES from '../../common/constants/FormatSizes';
import { FixBannerPageLayout } from '../../common/Mobile/FixBannerPageLayout';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';

export function ChatView({ format, match, userInfo }) {
  const history = useHistory();
  const { userInfo: user } = useUserInfo(userInfo);
  const {
    activeChats,
    handleUpdateMessageRead,
    fetchCurrentUserChats,
  } = useFetchChats();
  const [openDrawer, setOpenDrawer] = React.useState(!match.params.serialId);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const {
    chatDetails,
    handleChatChange,
    fetchChatDetailsBySerialId,
    loading,
  } = useFetchChatDetails(
    match.params.serialId,
    history,
    handleUpdateMessageRead,
    handleDrawerClose,
  );

  if (format === FORMAT_SIZES.MOBILE || format === FORMAT_SIZES.TABLET) {
    return (
      <FixBannerPageLayout
        isOpen={openDrawer}
        hidePanelControls={true}
        zIndex={1098}
        leftPanel={
          <LeftSection
            activeChats={activeChats}
            onChatChange={handleChatChange}
            onFetchCurrentUserChats={fetchCurrentUserChats}
            user={user}
            isMobile={true}
          />
        }
        actionButton={
          <IconButton
            onClick={() => {
              history.push('/chat');
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        }
      >
        <MiddleSection
          chatDetails={chatDetails}
          chatLoading={loading}
          onUpdateMessageRead={handleUpdateMessageRead}
          fetchChatDetailsBySerialId={fetchChatDetailsBySerialId}
          userInfo={user}
          isMobile={true}
          format={format}
        />
      </FixBannerPageLayout>
    );
  }

  return (
    <PageLayout>
      <LeftSection
        activeChats={activeChats}
        onChatChange={handleChatChange}
        onFetchCurrentUserChats={fetchCurrentUserChats}
        user={user}
      />
      <MiddleSection
        chatDetails={chatDetails}
        chatLoading={loading}
        onUpdateMessageRead={handleUpdateMessageRead}
        fetchChatDetailsBySerialId={fetchChatDetailsBySerialId}
        userInfo={user}
        format={format}
      />
    </PageLayout>
  );
}
