import React, { useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import useFetchFilteredAssetsAndUsers from '../hooks/useFetchFilteredAssetsAndUsers';
import { LeftPanelFilters } from './LeftPanelFilters';
import { MiddleSection } from './MiddleSection';
import { useHistory, useLocation } from 'react-router-dom';
import FORMAT_SIZES from '../../common/constants/FormatSizes';
import { FixBannerPageLayout } from '../../common/Mobile/FixBannerPageLayout';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';
import useGetCategories from '../../common/hooks/useGetCategories';

export function AssetSearchPage({ format, userInfo, isProfileCompleted }) {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const { categories, loading: loadingCategories } = useGetCategories(true);
  const {
    assets,
    searchedUsers,
    loaded,
    handleFilterChange,
    handleSearchChange,
    filters,
    clearFilterAndSearch,
  } = useFetchFilteredAssetsAndUsers(query, history);
  const [hideAdvertisement, setHideAdvertisement] = useState(false);
  const isMobile = format === FORMAT_SIZES.MOBILE;
  const Component = isMobile ? FixBannerPageLayout : PageLayout;

  return (
    <Component
      leftPanel={
        <LeftPanelFilters
          filters={filters}
          onFilterChange={handleFilterChange}
          categories={categories}
          isMobile={isMobile}
        />
      }
      hideRightPanel={hideAdvertisement}
      actionButton={
        loaded ? (
          <IconButton
            sx={{ marginLeft: '14px' }}
            onClick={clearFilterAndSearch}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : null
      }
    >
      <MiddleSection
        isMobile={isMobile}
        assets={assets}
        searchedUsers={searchedUsers}
        assetAndUserLoaded={loaded}
        user={userInfo}
        isProfileCompleted={isProfileCompleted}
        filters={filters}
        categories={categories}
        onFilterChange={handleFilterChange}
        onSearchChange={handleSearchChange}
        setHideAdvertisement={setHideAdvertisement}
        clearFilterAndSearch={clearFilterAndSearch}
      />
    </Component>
  );
}
