import { ConfirmTermsAndConditionsForm } from './ConfirmTermsAndConditionsForm';
import { ProfileEssentialsForm } from './ProfileEssentialsForm';

const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
const PROFILE_ESSENTIALS = 'PROFILE_ESSENTIALS';

const FormSteps = {
  [TERMS_AND_CONDITIONS]: {
    key: TERMS_AND_CONDITIONS,
    header: 'Let’s Make It Official',
    subheader: 'Read through our terms and give us a thumbs-up to continue.',
    requiredFields: ['hasAgreed'],
    component: ConfirmTermsAndConditionsForm,
    previousStep: null,
    nextStep: PROFILE_ESSENTIALS,
  },
  [PROFILE_ESSENTIALS]: {
    key: PROFILE_ESSENTIALS,
    header: 'Tell us a bit about you',
    subheader:
      'We need you to complete your profile to personalize your experience.',
    requiredFields: ['firstname', 'surname', 'displaylocation'],
    component: ProfileEssentialsForm,
    previousStep: TERMS_AND_CONDITIONS,
    nextStep: null,
  },
};

export { TERMS_AND_CONDITIONS, PROFILE_ESSENTIALS, FormSteps };
