import axios from 'axios';
import _ from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { AuthContext } from './AuthContext';

function useGetUserGoals(userUuId) {
  const { authContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [userGoals, setUserGoals] = useState(localStorage.getItem('goals'));

  const fetchUserGoals = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/goals/user/${userUuId}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${authContext.token}`,
        },
      })
      .then(response => {
        setUserGoals(response.data[0]);
        localStorage.setItem('goals', JSON.stringify(response.data[0]));
        setLoaded(true);
        setLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  }, [userUuId]);

  if (userUuId && _.isEmpty(userGoals) && !loading && !loaded) {
    setLoading(true);
    fetchUserGoals();
  }

  return { userGoals, loading };
}

export default useGetUserGoals;
