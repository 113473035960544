import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import { fromApi } from '../models/categories';
import {
  ALL_CATEGORIES,
  ALL_SUBCATEGORIES,
  PILOT_CATEGORIES,
} from '../constants';

function useGetCategories(isPopulatedOnly) {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([
    ...ALL_CATEGORIES,
    ...ALL_SUBCATEGORIES,
    ...PILOT_CATEGORIES,
  ]);

  const fetchCategories = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API_URL}/categories${
          isPopulatedOnly ? '/populated' : ''
        }`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${authContext.token}`,
          },
        },
      )
      .then(response => {
        const customCategories = response.data.map(fromApi);
        const sortedCategories = _.orderBy(
          _.uniqBy(
            [
              ...(isPopulatedOnly ? [] : categories),
              ...customCategories,
              ...PILOT_CATEGORIES,
            ],
            'value',
          ),
          [c => c.label.toLowerCase()],
          ['asc'],
        );

        setCategories(sortedCategories);
        setLoaded(true);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoading(false);
        setLoaded(true);
      });
  };

  if (!loaded && !loading) {
    fetchCategories();
  }

  return { loading, categories };
}

export default useGetCategories;
