import React from 'react';
import { PageLayout } from './PageLayout';
import { LeftSection } from './LeftSection';
import { MiddleSection } from './MiddleSection';
import { fromApi } from '../common/models/user';
import FORMAT_SIZES from '../common/constants/FormatSizes';
import Placeholder from '../../assets/images/placeholderAvatar.svg';
import { FixBannerPageLayout } from '../common/Mobile/FixBannerPageLayout';
import styled from 'styled-components';
import { Avatar } from '@mui/material';

const ProfilePicture = styled(Avatar)`
  margin: -52px auto 0 auto !important;
  width: 50% !important;
  margin-bottom: 14px !important;
  height: auto !important;
  border: 4px solid #fff !important;
`;

export function ContactUsPage(props) {
  const { userInfo, format } = props;
  const user = fromApi(userInfo ?? {});
  const isMobile = format === FORMAT_SIZES.MOBILE;

  if (isMobile) {
    return (
      <FixBannerPageLayout color="gray" expanded="true">
        <ProfilePicture
          variant="round"
          src={user.profilepicture || Placeholder}
        />
        <MiddleSection user={user} isMobile={isMobile} />
      </FixBannerPageLayout>
    );
  }

  return (
    <PageLayout>
      <LeftSection user={user} />
      <MiddleSection user={user} />
    </PageLayout>
  );
}
