import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import axios from 'axios';
import { genericSuccess, genericUpdateError } from '../../toaster/utils';

export default function useUploadFinalImage(callback) {
  const { authContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const uploadFinalImage = (formData, resetFields) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_PUBLIC_API_URL}/images/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${authContext.token}`,
        },
      })
      .then(({ data }) => {
        callback(data, formData);
        resetFields && resetFields();
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        genericUpdateError(`Failed to upload your image: ${error}`);
      });
  };

  return {
    loading,
    uploadFinalImage,
  };
}
