import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import { getToken, getUserData, isTokenNotValid } from './utils';
import OneSignal from 'react-onesignal';
import { isUserProfileCompleted } from '../common/isUserProfileCompleted';
import { isUserProfileActive } from '../common/isUserProfileActive';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const currentToken = getToken();
  const [token, setAuthToken] = useState(currentToken);
  const { isAuthenticated } = useAuth0();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isAuth0Authenticated, setIsAuth0Authenticated] = useState(
    isAuthenticated,
  );

  useEffect(() => {
    if (token !== null && !isTokenNotValid(token)) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        serviceWorkerPath: '/push/onesignal/OneSignalSDKWorker.js',
        serviceWorkerParam: { scope: '/push/onesignal' },
      })
        .then(() => getUserData(token))
        .then(userInfo => {
          if (userInfo && userInfo.notificationpush) {
            OneSignal.Slidedown.promptPush();
            OneSignal.login(userInfo.data?.uuid);
            setIsProfileCompleted(isUserProfileCompleted(userInfo));
            setIsProfileActive(isUserProfileActive(userInfo));
          }
        });
    }
  }, [token]);

  const authContext = {
    token,
    userData: getUserData,
    isAuth0Authenticated,
    setIsAuth0Authenticated,
    setAuthToken,
  };

  const socket = io(`${process.env.REACT_APP_PUBLIC_API_URL}`, {
    query: { token },
  });

  const socketContext = { socket };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID}
      authorizationParams={{
        audience: 'wesuap-crude-api',
        redirect_uri: `${process.env.REACT_APP_LOGIN_REDIRECT_URI}/search`,
      }}
    >
      <AuthContext.Provider
        value={{
          authContext,
          socketContext,
          isProfileCompleted,
          isProfileActive,
          setIsProfileCompleted,
          setIsProfileActive,
          setAuthToken,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Auth0Provider>
  );
};
AuthProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
};
