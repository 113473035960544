import { useContext, useState } from 'react';
import { AuthContext } from '../app/AuthContext';
import axios from 'axios';
import { fromApi } from '../common/models/user';
import { shuffle } from 'lodash';

export function useGetSuggestedFollowing() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [suggestedFollowingUsers, setSuggestedFollowingUsers] = useState([]);
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;

  const getSuggestedUsers = () => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/follow/suggested`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setSuggestedFollowingUsers(
          shuffle(response.data ?? [])
            .slice(0, 7)
            .map(fromApi) ?? [],
        );
        setLoaded(true);
        setLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  if (!loaded && !loading) {
    setLoading(true);
    getSuggestedUsers();
  }

  return { suggestedFollowingUsers, loading };
}
