import { styled as styledMui } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { THEME_COLORS } from '../common/colors';

const FormInput = styledMui(TextField)(() => ({
  label: {
    fontSize: '1.2rem',
  },
  '.MuiInputBase-root': {
    borderRadius: 0,
    marginBottom: 24,
  },
  '.Mui-focused span': {
    color: THEME_COLORS.primary,
  },
  'label.Mui-focused': {
    color: THEME_COLORS.primary,
  },
  '.MuiFormLabel-filled + div legend': {
    paddingRight: 12,
  },
  '.Mui-focused legend': {
    paddingRight: 12,
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: THEME_COLORS.primary,
  },
}));

export default FormInput;
