import React from 'react';
import styled from 'styled-components';
import FormInput from '../FormInput';

const SomethingElseContainer = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export function OpenTextBoxForm({ handleFieldChange, value }) {
  return (
    <SomethingElseContainer>
      <FormInput
        htmlFor="somethingElse"
        name="somethingElse"
        multiline
        rows={5}
        value={value}
        id="somethingElse"
        required
        label="Something else in mind?"
        onChange={handleFieldChange}
        variant="outlined"
      />
    </SomethingElseContainer>
  );
}
