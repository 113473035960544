import axios from 'axios';
import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import { validation } from './validation';
import {
  formValidationError,
  genericSuccess,
  genericUpdateError,
} from '../../toaster/utils';
import { eventTrigger } from '../../analytics/eventTrigger';

function updateContractApi(authContext, formData) {
  return axios.put(
    `${process.env.REACT_APP_PUBLIC_API_URL}/contracts/user/${formData.id}`,
    formData,
    {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${authContext.token}`,
      },
    },
  );
}

function createContractApi(authContext, formData) {
  return axios.post(
    `${process.env.REACT_APP_PUBLIC_API_URL}/contracts/user`,
    formData,
    {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${authContext.token}`,
      },
    },
  );
}

function useCreateOrUpdateContract(setOpenContractModal, onUpdateSuapContract) {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [errors, setErrors] = useState({
    description: '',
    estimatedCost: '',
    dateOfCompletion: '',
    asset: '',
  });

  const handleSuccess = response => {
    onUpdateSuapContract(response.data.contractId);
    setLoaded(true);
    setOpenContractModal('');
    genericSuccess(response.data.message);
    return response.data;
  };

  const handleError = error => {
    if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
    genericUpdateError(`Failed to update your suap agreement: ${error}`);
  };

  const updateContract = (formData, message) => {
    const validations = validation();
    const errorFields = Object.entries(formData)
      .filter(([key]) => Object.keys(errors).includes(key))
      .reduce((err, entries) => {
        err[entries[0]] = validations[entries[0]](entries);
        return err;
      }, {});

    setErrors(errorFields);

    if (!Object.values(errorFields).some(v => v)) {
      eventTrigger('Updated offer', 'form', 'form_submission');
      updateContractApi(authContext, formData)
        .then(response => {
          if (formData.status === 'APPROVED') {
            eventTrigger('Approved offer', 'form', 'confirm_suap');
          }
          handleSuccess(response, message);
        })
        .catch(handleError);
    } else {
      formValidationError('Please fill in all required fields');
    }
  };

  const createContract = (formData, message) => {
    const validations = validation();
    const errorFields = Object.entries(formData)
      .filter(([key]) => Object.keys(errors).includes(key))
      .reduce((err, entries) => {
        err[entries[0]] = validations[entries[0]](entries);
        return err;
      }, {});

    setErrors(errorFields);

    if (!Object.values(errorFields).some(v => v)) {
      eventTrigger('Propose offer', 'form', 'proposed_suap');
      createContractApi(authContext, formData)
        .then(response => handleSuccess(response, message))
        .catch(handleError);
    }
  };

  return { updateContract, createContract, errors };
}

export default useCreateOrUpdateContract;
