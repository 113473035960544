import { useContext, useState } from 'react';
import axios from 'axios';
import { genericSuccess, genericUpdateError } from '../../toaster/utils';
import { AuthContext } from '../AuthContext';

export function useSubscribe() {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const [loading, setLoading] = useState(false);

  const onSubscribe = email => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API_URL}/subscribe`,
        {
          email: email.toLowerCase(),
        },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setLoading(false);
        if (data.success === true) {
          genericSuccess('You have been subscribed!');
        } else {
          genericUpdateError(data.error);
        }
      });
  };

  return {
    onSubscribe,
    loading,
  };
}
