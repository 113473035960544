import { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../app/AuthContext';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { fromApi as fromAssetApi } from '../common/models/post';
import { fromApi as fromContractApi } from '../common/models/contractPost';

export function useGetFollowerContent() {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const [assetLoading, setAssetLoading] = useState(false);
  const [completedSuapLoading, setCompletedSuapLoading] = useState(false);
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [completedSuapLoaded, setCompletedSuapLoaded] = useState(false);
  const [followingAssetContent, setFollowingAssetContent] = useState([]);
  const [followingSuapsContent, setFollowingSuapsContent] = useState([]);

  const getFollowingAssets = () => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/posts/posts`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setFollowingAssetContent(
          (data ?? [])
            .filter(record => !!record.images.length)
            .map(record => ({
              modifiedDate: moment(
                record.modified_at ?? record.created_at,
              ).format('dddd, MMMM Do YYYY, h:mm:ss a'),
              index: -moment(record.modified_at ?? record.created_at).unix(),
              type: 'ASSET',
              isFollowing: record.is_following,
              data: fromAssetApi(record),
            })),
        );
        setAssetLoaded(true);
        setAssetLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setAssetLoaded(true);
        setAssetLoading(false);
      });
  };

  const getCompletedSuaps = () => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/posts/contracts`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setFollowingSuapsContent(
          (data ?? []).map(record => ({
            modifiedDate: moment(
              record.modified_at ?? record.created_at,
            ).format('dddd, MMMM Do YYYY, h:mm:ss a'),
            index: -moment(record.modified_at ?? record.created_at).unix(),
            type: 'CONTRACT',
            isFollowing: record.is_following,
            data: fromContractApi(record),
          })),
        );
        setCompletedSuapLoaded(true);
        setCompletedSuapLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setCompletedSuapLoaded(true);
        setCompletedSuapLoading(false);
      });
  };

  if (!assetLoaded && !assetLoading) {
    setAssetLoading(true);
    getFollowingAssets();
  }

  if (!completedSuapLoaded && !completedSuapLoading) {
    setCompletedSuapLoading(true);
    getCompletedSuaps();
  }

  const followingContent = useMemo(
    () => [...followingAssetContent, ...followingSuapsContent],
    [followingAssetContent, followingSuapsContent],
  );

  return {
    followingContent,
    loading: assetLoading || completedSuapLoading,
  };
}
