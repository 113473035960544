import React from 'react';
import styled from 'styled-components';
import HomeLogo from '../common/HomeLogo';
import { NavBar } from '../common/NavBar';
import AdVertical from '../../assets/ads/MoreAssetsVertical.png';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 0.8fr 1.1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: 'Logo Navigation Navigation Navigation Navigation';
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-top: 24px;
  background-color: #f4f5f4;
  min-height: 100px;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 5rem;
  padding-right: 4rem;
`;

const Form = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  min-height: 90vh;
  grid-template-columns: 1.1fr 1fr 1fr 0.8fr 1.1fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: 'LeftPanel MiddlePanel MiddlePanel MiddlePanel MiddlePanel';
`;

const Section = styled.div`
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  ${({ type }) =>
    type === 'view' &&
    `
    margin: 0 auto;
    max-width: 640px;
  `}
`;

export function PageLayout({ children }) {
  return (
    <Container>
      <HeaderContainer>
        <Header>
          <Logo>
            <Section>
              <HomeLogo position="absolute" />
            </Section>
          </Logo>
          <Navigation>
            <Section>
              <NavBar condense={true} />
            </Section>
          </Navigation>
        </Header>
      </HeaderContainer>
      <Form>{children}</Form>
    </Container>
  );
}
