import { useCallback, useEffect } from 'react';
import { capitalizeFirstLetter } from '../../common/utils/capitalizeFirstLetter';
import { genericUpdateError } from '../../toaster/utils';
import { useGenericApiRequest } from '../../common/hooks/useGenericApiRequest';

function toUserForm(user) {
  return {
    id: user.id,
    firstname: user.firstname,
    surname: user.surname,
    displaylocation: user.displaylocation,
  };
}

export default function useSignup() {
  const { loading, executeRequest, response, loaded } = useGenericApiRequest({
    url: `${process.env.REACT_APP_PUBLIC_API_URL}/users`,
    method: 'PUT',
  });

  useEffect(() => {
    if (response?.success) {
      window.location.pathname = '/search';
    }
  }, [loaded, response]);

  const updateProfile = useCallback(formData => {
    const data = toUserForm(formData);
    executeRequest()({
      ...data,
      firstname: capitalizeFirstLetter(data.firstname),
      surname: capitalizeFirstLetter(data.surname),
    })
      .then(response => {})
      .catch(error => {
        genericUpdateError(`Failed to save your information.`);
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
      });
  }, []);

  return { loading, updateProfile };
}
