import React, { useState } from 'react';
import { GoalForm } from './goals/GoalForm';
import SignupEssentialsForm from './signup';
import useUserFetchProfile from '../profile/hooks/useUserFetchProfile';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const GOAL_WORKFLOW = 'GOAL_WORKFLOW';
const PROFILE_WORKFLOW = 'PROFILE_WORKFLOW';

export function EssentialFlow({ userInfo }) {
  const {
    userInfo: user,
    setUserInfo,
    loading: loadingUser,
    loaded: loadedUser,
  } = useUserFetchProfile(userInfo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const [currentEssentialFlow, setCurrentEssentialFlow] = useState(
    GOAL_WORKFLOW,
  );

  const onGoalSkip = () => {
    localStorage.setItem('skipGoalForNow', 'true');
    if (user.firstname && user.surname && user.displaylocation) {
      window.location.pathname = '/search';
    } else {
      setCurrentEssentialFlow(PROFILE_WORKFLOW);
    }
  };

  const onProfileSkip = () => {
    window.location.pathname = '/search';
  };

  const onBackToGoal = () => {
    setCurrentEssentialFlow(PROFILE_WORKFLOW);
  };

  return currentEssentialFlow === GOAL_WORKFLOW ? (
    <GoalForm isMobile={isMobile} user={user} onGoalSkip={onGoalSkip} />
  ) : (
    <SignupEssentialsForm
      onBackToGoal={onBackToGoal}
      onProfileSkip={onProfileSkip}
      user={user}
      setUserInfo={setUserInfo}
      loadingUser={loadingUser}
      loaded={loadedUser}
    />
  );
}
