import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  loginSuccess,
  logoutSuccess,
  signupSuccess,
  alreadyLoggedin,
  userValidated,
  userNotValidated,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  userNotFound,
  profileNotCompleted,
  userBlockedYou,
  accessDenied,
  contactUsSuccessful,
  updateProfileSuccess,
  invitationNotValidated,
  addAssetsToSuap,
  signupOauthSuccess,
  createGoalSuccessful,
} from './utils';
import { useHistory } from 'react-router-dom';

const Toaster = () => {
  const history = useHistory();
  const param = history.location?.search?.replace('?message=', '');

  const [toastDebounced] = useDebouncedCallback(message => {
    switch (message) {
      case 'login_success':
        loginSuccess();
        break;
      case 'logout_success':
        logoutSuccess();
        break;
      case 'signup_success':
        signupSuccess();
        break;
      case 'signup_oauth_success':
        signupOauthSuccess();
        break;
      case 'already_loggedin':
        alreadyLoggedin();
        break;
      case 'user_validated':
        userValidated();
        break;
      case 'user_not_validated':
        userNotValidated();
        break;
      case 'forgotPassword_success':
        forgotPasswordSuccess();
        break;
      case 'reset_password_success':
        resetPasswordSuccess();
        break;
      case 'user_not_found':
        userNotFound();
        break;
      case 'profile_not_completed':
        profileNotCompleted();
        break;
      case 'user_blocked_you':
        userBlockedYou();
        break;
      case 'access_denied':
        accessDenied();
        break;
      case 'cant_access_chat':
        accessDenied();
        break;
      case 'update_profile_success':
        updateProfileSuccess();
        break;
      case 'contact_us_success':
        contactUsSuccessful();
        break;
      case 'invalid_invite_token':
        invitationNotValidated();
        break;
      case 'add_assets_to_suap':
        addAssetsToSuap();
        break;
      case 'add_goal_success':
        createGoalSuccessful();
        break;
      default:
        break;
    }
  }, 500);

  if (param !== undefined) {
    toastDebounced(param);
  }
  return null;
};

export default Toaster;
