import * as GoalsConstants from './constants';
import { NONE_OF_THESE, SOMETHING_ELSE, STUDIO_TIME } from './constants';

const FormSteps = {
  [GoalsConstants.HOW_CAN_WE_HELP]: {
    key: GoalsConstants.HOW_CAN_WE_HELP,
    header: 'How can we help?',
    subheader:
      'Setting a goal helps us connect you with the right people and resources faster.',
    hint: 'Please select one option:',
    options: Object.entries(GoalsConstants.GoalStepLabels).map(
      ([value, label]) => ({
        label,
        value,
      }),
    ),
    footer:
      'Fit into more than one category? Don’t worry! You can add it to your profile later. For now, select one.',
    allowMultiples: false,
  },
  [GoalsConstants.CREATIVE_PROJECTS]: {
    key: GoalsConstants.CREATIVE_PROJECTS,
    header: 'What do you need the most help with?',
    subheader: 'Select as many as you wish.',
    options: Object.entries(GoalsConstants.GoalOptionLabels)
      .filter(([value]) => value !== NONE_OF_THESE)
      .map(([value, label]) => ({
        label,
        value,
      })),
    allowMultiples: true,
    somethingElseHeader:
      'What other services do you need to support your creative projects?',
    somethingElseSubheader: null,
  },
  [GoalsConstants.BOOST_MY_PROJECT]: {
    key: GoalsConstants.BOOST_MY_PROJECT,
    header: 'Which do you already have access to?',
    subheader: 'Select as many as you wish.',
    options: Object.entries(GoalsConstants.GoalOptionLabels)
      .filter(([value]) => ![SOMETHING_ELSE, STUDIO_TIME].includes(value))
      .map(([value, label]) => ({
        label,
        value,
      })),
    allowMultiples: true,
    somethingElseHeader: null,
    somethingElseSubheader: null,
  },
  [GoalsConstants.SAVE_MONEY]: {
    key: GoalsConstants.SAVE_MONEY,
    header: 'What do you wanna save money on?',
    subheader: 'Select as many as you wish.',
    options: Object.entries(GoalsConstants.GoalOptionSaveMoneyLabels).map(
      ([value, label]) => ({
        label,
        value,
      }),
    ),
    allowMultiples: true,
    somethingElseHeader:
      'What are other top expenses you’d like to offset aside from rent and utilities?',
    somethingElseSubheader: null,
  },
};

export { FormSteps };
