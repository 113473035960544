import React, { useState } from 'react';
import { SubHeader } from '../../profile/editProfile/styles';
import { Grid, InputAdornment, TextField } from '@mui/material';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import { EditButton } from '../../profile/editProfile/EditButton';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import FormControl from '@mui/material/FormControl';
import { styled as styledMui } from '@mui/material/styles';
import { THEME_COLORS } from '../../common/colors';
import styled from 'styled-components';
import useEnableLocation from '../../profile/hooks/useEnableLocation';
import FormInput from '../FormInput';

const ManuallyEnableLocationOption = styled.div`
  color: ${THEME_COLORS.primary};
  font-size: 0.8rem;
  margin-top: -0.8rem;
  margin-bottom: 1.4rem;
  cursor: pointer;
`;

const LocationContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  height: 45px;
  margin-bottom: 24px;
  position: relative;
`;

export function ProfileEssentialsForm({
  handleFieldChange,
  handleFieldsChange,
  inputs,
}) {
  const {
    loading: locationLoading,
    handleEnableLocation,
    locationDisabled,
  } = useEnableLocation(handleFieldsChange);

  const [enabledManualLocation, setEnabledManualLocation] = useState(false);

  return (
    <>
      <FormInput
        htmlFor="firstname"
        name="firstname"
        id="firstname"
        value={inputs.firstname}
        required
        label="First name"
        onChange={handleFieldChange('firstname')}
        variant="outlined"
      />
      <FormInput
        htmlFor="surname"
        name="surname"
        value={inputs.surname}
        id="surname"
        required
        label="Last name"
        onChange={handleFieldChange('surname')}
        variant="outlined"
      />
      <FormControl>
        {(locationDisabled || enabledManualLocation) && (
          <FormInput
            placeholder="Brooklyn, NY, United States"
            onChange={handleFieldChange('displaylocation')}
            value={inputs.displaylocation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationOffRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        {!locationDisabled && !enabledManualLocation && (
          <LocationContainer>
            {inputs.displaylocation || 'No location *'}
            <EditButton
              onClick={handleEnableLocation}
              loading={locationLoading}
              disabled={locationDisabled}
              icon={LocationOnRoundedIcon}
            />
          </LocationContainer>
        )}
        <ManuallyEnableLocationOption
          onClick={() => setEnabledManualLocation(true)}
        >
          Click here to enter your location manually
        </ManuallyEnableLocationOption>
      </FormControl>
    </>
  );
}
