import { useCallback, useState } from 'react';
import { fromApi } from '../../common/models/user';
import useUploadFinalImage from '../../common/hooks/useUploadFinalImage';
import { validation } from '../validation';
import { capitalizeFirstLetter } from '../../common/utils/capitalizeFirstLetter';
import { formValidationError, genericUpdateError } from '../../toaster/utils';
import { toUserForm } from '../editProfile/toUserForm';
import { isUserProfileCompleted } from '../../common/isUserProfileCompleted';
import { useGenericApiRequest } from '../../common/hooks/useGenericApiRequest';
import checkValidations from '../../common/utils/checkValidations';

const INITIAL_ERROR_STATE = {
  firstname: '',
  surname: '',
  profilepicture: '',
  profilevalues: '',
  displaylocation: '',
  topcategories: '',
  top_my_asset_categories: '',
  description: '',
  username: '',
  phone: '',
};

export default function useEditProfile(
  userData,
  setUserInfo,
  setOpenModal,
  userAssets,
  setDraftTimestamp,
) {
  const { loading, executeRequest } = useGenericApiRequest({
    url: `${process.env.REACT_APP_PUBLIC_API_URL}/users`,
    method: 'PUT',
  });

  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  const validateProfile = checkValidations(validation(), setErrors, errors);

  const { loading: loadingImage, uploadFinalImage } = useUploadFinalImage(
    data => {
      setUserInfo({
        ...userData,
        profilepicture: data.Location,
      });
      setOpenModal(null);
    },
  );

  const updateProfile = useCallback(
    formData => {
      if (validateProfile(formData)) {
        const data = toUserForm(formData);
        executeRequest()({
          ...data,
          firstname: capitalizeFirstLetter(data.firstname),
          surname: capitalizeFirstLetter(data.surname),
        })
          .then(() => {
            const updatedUserData = fromApi({
              ...userData,
              ...formData,
              assetcount: (userAssets || []).length,
            });
            setUserInfo(updatedUserData);
            setDraftTimestamp(null);
            localStorage.removeItem(`draft-profile-${userData.uuid}`);

            if (isUserProfileCompleted(updatedUserData)) {
              window.location = '/profile?message=update_profile_success';
            }
          })
          .catch(error => {
            genericUpdateError(`Failed to update your profile: ${error}`);
            if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
          });
      } else {
        formValidationError('Please fill out all required fields.');
      }
    },
    [userAssets],
  );

  return { loading, loadingImage, updateProfile, uploadFinalImage, errors };
}
