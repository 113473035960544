/* eslint-disable no-console */
import decode from 'jwt-decode';
import axios from 'axios';

export const getToken = () => {
  return localStorage.getItem('token');
};

export const isTokenNotValid = token => {
  const currentTime = Date.now() / 1000;
  try {
    const decoded = decode(token);

    if (
      !decoded?.iss ||
      !decoded.iss.includes(process.env.REACT_APP_AUTH_0_DOMAIN)
    ) {
      if (process.env.REACT_APP_VERBOSE === 'true')
        console.log('Your token is not Auth0');
      return true;
    }

    if (decoded.exp < currentTime) {
      if (process.env.REACT_APP_VERBOSE === 'true')
        console.log('Your token is expired');
      return true;
    }
    return false;
  } catch (err) {
    if (process.env.REACT_APP_VERBOSE === 'true') console.log(err);
    return true;
  }
};

export const getUserData = async token => {
  if (!token || isTokenNotValid(token)) {
    return null;
  }
  const userData = await axios.get(
    `${process.env.REACT_APP_PUBLIC_API_URL}/auth/checkToken`,
    {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        authorization: `Bearer ${token}`,
      },
    },
  );

  return userData.data;
};
