import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import axios from 'axios';
import { genericUpdateError } from '../../toaster/utils';
import { eventTrigger } from '../../analytics/eventTrigger';

export default function useCreateChat(history) {
  const { authContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const initiateSuap = formData => {
    eventTrigger('Initiated suap', 'form', 'initiate_suap');
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_PUBLIC_API_URL}/chat`, formData, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${authContext.token}`,
        },
      })
      .then(({ data }) => {
        window.location = `/chat/${data.serial_id}`;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        genericUpdateError(`Failed to start a suap: ${error}`);
      });
  };

  return { initiateSuap, loading };
}
