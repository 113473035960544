import React from 'react';
import Button from '@mui/material/Button';
import { styled as styledMui } from '@mui/material/styles';
import { THEME_COLORS } from '../common/colors';

const SkipBtn = styledMui(Button)(() => ({
  color: THEME_COLORS.subdued,
  textTransform: 'unset',
  textDecoration: 'underline',
  fontSize: '1rem',
  fontWeight: '400',
}));

export function SkipButton({ onSkip }) {
  return (
    <SkipBtn size="small" onClick={onSkip}>
      Skip for now
    </SkipBtn>
  );
}
