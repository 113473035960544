import { DELIVERY_OPTIONS } from '../constants';

export function getDeliveryMethodFromValue(method) {
  return (
    DELIVERY_OPTIONS.filter(({ value }) => value === method).map(
      ({ label }) => label,
    )[0] ?? ''
  );
}

export function getDeliveryMethodsFromValue(methods) {
  return (
    DELIVERY_OPTIONS.filter(({ value }) => (methods ?? []).includes(value))
      .map(({ label }) => label)
      .join(', ') ?? ''
  );
}
