import { useContext, useState } from 'react';
import { AuthContext } from '../app/AuthContext';
import axios from 'axios';

export function useIsAlreadyFollowing(id) {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;

  const isAlreadyFollowing = () => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API_URL}/follow/isAlreadyFollowing/${id}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        if (response.data.success) {
          setIsFollowing(response.data.isAlreadyFollowing);
        }
        setLoaded(true);
        setLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  if (!loaded && !loading && id) {
    setLoading(true);
    isAlreadyFollowing();
  }

  return { isFollowing, loading, setIsFollowing };
}
