import React, { useCallback, useState } from 'react';
import { SecondaryButton } from '../SecondaryButton';
import { THEME_COLORS } from '../../common/colors';

import { PageFlowLayout } from '../PageLayout';
import { HOW_CAN_WE_HELP, SOMETHING_ELSE } from './constants';
import { FormSteps } from './FormSteps';
import IconButton from '@mui/material/IconButton';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { SkipButton } from '../SkipButton';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { useGetViewportSize } from '../../common/hooks/useGetViewpointSize';
import { OpenTextBoxForm } from './OpenTextBoxForm';
import useCreateGoals from './useCreateGoals';

const BackButton = styledMui(IconButton)(() => ({
  padding: 0,
}));

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Options = styled.div`
  max-height: ${({ isMobile, screenHeight }) =>
    isMobile
      ? `calc(${screenHeight ? `${screenHeight}px` : '100vh'} - 365px)`
      : '300px'};
  overflow-y: auto;
  margin: 0 -25px;
  padding: 0 20px;
`;

const Option = styled.div`
  border-radius: 1rem;
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  text-align: center;
  margin: 1rem auto;
  width: 100%;
  max-width: 270px;

  ${({ active }) =>
    active &&
    `
  background-color: ${THEME_COLORS.primary};
  color: #fff;
  `}
`;

export function GoalForm({ user, onGoalSkip, isMobile }) {
  const { height: screenHeight } = useGetViewportSize();
  const [currentStep, setCurrentStep] = useState(FormSteps[HOW_CAN_WE_HELP]);
  const { loading: saving, createGoal } = useCreateGoals(user.uuid, onGoalSkip);
  const [goals, setGoals] = useState({
    HOW_CAN_WE_HELP: [],
    SOMETHING_ELSE: '',
  });

  const [showSomethingElseForm, setShowSomethingElseForm] = useState(false);
  const currentGoalValues = goals[currentStep.key];
  const hasValues = currentGoalValues.length > 0;
  const somethingElseNeedsToBeAdded =
    currentGoalValues.includes(SOMETHING_ELSE) && !showSomethingElseForm;

  const onPrevious = () => {
    if (showSomethingElseForm) {
      setShowSomethingElseForm(false);
    } else {
      const newGoals = Object.fromEntries(
        Object.entries(goals).filter(([key]) => key !== currentStep.key),
      );
      setGoals(newGoals);
      setCurrentStep(FormSteps[HOW_CAN_WE_HELP]);
    }
  };

  const handleSomethingElseChange = useCallback(
    event => {
      setGoals({
        ...goals,
        [SOMETHING_ELSE]: event.target.value,
      });
    },
    [goals, currentStep],
  );

  const handleOptionsChange = useCallback(
    value => {
      if (currentStep.allowMultiples) {
        const currentGoals = goals[currentStep.key] ?? [];
        const newValues = currentGoals.includes(value)
          ? currentGoals.filter(goal => goal !== value)
          : [...currentGoals, value];
        setGoals({
          ...goals,
          [currentStep.key]: newValues,
        });
      } else {
        setGoals({
          ...goals,
          [currentStep.key]: value,
          [value]: [],
        });

        setCurrentStep(FormSteps[value]);
      }
    },
    [goals, currentStep],
  );

  const onSubmit = useCallback(() => {
    if (somethingElseNeedsToBeAdded) {
      setShowSomethingElseForm(true);
    } else {
      localStorage.setItem('goals', JSON.stringify(goals));
      createGoal(goals);
    }
  }, [somethingElseNeedsToBeAdded, goals]);

  const isSubmissionDisabled =
    !hasValues || (showSomethingElseForm && !goals[SOMETHING_ELSE]);

  return (
    <PageFlowLayout
      header={
        showSomethingElseForm
          ? currentStep.somethingElseHeader
          : currentStep.header
      }
      subheader={
        showSomethingElseForm
          ? currentStep.somethingElseSubheader
          : currentStep.subheader
      }
      hint={currentStep.hint}
      footer={currentStep.footer}
      onSkip={onGoalSkip}
    >
      <>
        {showSomethingElseForm && (
          <OpenTextBoxForm
            handleFieldChange={handleSomethingElseChange}
            value={goals[SOMETHING_ELSE]}
          />
        )}
        {!showSomethingElseForm && (
          <Options isMobile={isMobile} screenHeight={screenHeight}>
            {currentStep.options.map(({ label, value }) => {
              return (
                <Option
                  active={currentGoalValues.includes(value)}
                  onClick={() => handleOptionsChange(value)}
                >
                  {label}
                </Option>
              );
            })}
          </Options>
        )}
        {currentStep.allowMultiples && (
          <ActionContainer>
            <div>
              <BackButton
                color="secondary"
                aria-label="back"
                onClick={onPrevious}
              >
                <ArrowBackIosNewRounded />
              </BackButton>
            </div>
            <div>
              <SecondaryButton
                disabled={isSubmissionDisabled}
                loading={saving}
                color={THEME_COLORS.primary}
                condensed={true}
                onClick={onSubmit}
              >
                {somethingElseNeedsToBeAdded ? 'Add' : 'Next'}
              </SecondaryButton>
            </div>
          </ActionContainer>
        )}
      </>
    </PageFlowLayout>
  );
}
