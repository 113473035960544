import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { MinimalLink } from '../../profile/styledComponents';

const TermsAndConditionPrivacyPolicyContainer = styled.div`
  margin-bottom: 4px;
`;

const CustomCheckbox = muiStyled(Checkbox)(() => ({
  '&': {
    padding: '0',
    paddingRight: 6,
    marginTop: -4,
  },
  svg: {
    fontSize: '2rem',
  },
}));

export function ConfirmTermsAndConditionsForm({
  handleCheckboxChange,
  inputs,
}) {
  return (
    <TermsAndConditionPrivacyPolicyContainer>
      <CustomCheckbox
        size="small"
        value={inputs.hasAgreed}
        name="hasAgreed"
        onChange={handleCheckboxChange}
      />
      <Typography component="span" variant="h5">
        By signing up, I have read and agree to WeSuap's{' '}
        <MinimalLink href="/terms-and-conditions" target="_blank" variant="h5">
          <b>Terms & conditions, and Privacy Policy</b>
        </MinimalLink>
      </Typography>
    </TermsAndConditionPrivacyPolicyContainer>
  );
}
