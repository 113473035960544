import { useCallback, useEffect } from 'react';
import { genericSuccess, genericUpdateError } from '../../toaster/utils';
import { useGenericApiRequest } from '../../common/hooks/useGenericApiRequest';

function toGoalForm(uuid, goals) {
  return {
    userUuid: uuid,
    goals,
  };
}

export default function useCreateGoals(uuid, onSkipGoal) {
  const { loading, executeRequest, response, loaded } = useGenericApiRequest({
    url: `${process.env.REACT_APP_PUBLIC_API_URL}/goals`,
    method: 'POST',
  });

  useEffect(() => {
    if (response?.created) {
      onSkipGoal && onSkipGoal();
    }
  }, [loaded, response]);

  const createGoal = useCallback(
    goals => {
      const data = toGoalForm(uuid, goals);
      executeRequest()(data)
        .then(response => {
          genericSuccess('Your goals have been saved!.');
        })
        .catch(error => {
          genericUpdateError(`Failed to save your information.`);
          if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        });
    },
    [uuid],
  );

  return { loading, createGoal };
}
