import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { fromApi } from '../../common/models/asset';
import { AuthContext } from '../../app/AuthContext';

function useFetchUserAssets(assetId) {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(fromApi({}));

  const fetchAsset = id => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/assets/${id}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${authContext.token}`,
        },
      })
      .then(response => {
        setAsset(fromApi(response.data));
        setLoaded(true);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  if (assetId && !asset.id && !loading && !loaded) {
    setLoading(true);
    fetchAsset(assetId);
  }

  return { asset, setAsset, loaded, fetchAsset };
}

export default useFetchUserAssets;
