import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import axios from 'axios';
import * as Contract from '../../common/models/contract';
import _ from 'lodash';

export default function useSuapContract(
  chatId,
  serialId,
  contractId,
  requestedUserId,
  requesterUserId,
  setSuapContractModal,
  fetchChatDetailsBySerialId,
) {
  const { authContext, socketContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState({});
  const [updatedContractId, setUpdatedContract] = useState(contractId);

  const fetchContract = id => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API_URL}/contracts/${contractId ??
          updatedContractId ??
          id}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${authContext.token}`,
          },
        },
      )
      .then(response => {
        setContractDetails(Contract.fromApi(response.data));
        setUpdatedContract(response.data.id);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoading(false);
      });
  };

  const createContract = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API_URL}/contracts`,
        {
          chatId,
          serialId,
          requestedUserId,
          requesterUserId,
        },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${authContext.token}`,
          },
        },
      )
      .then(response => {
        setUpdatedContract(response.data.id);
        fetchContract(response.data.id);
        fetchChatDetailsBySerialId(serialId);
        setSuapContractModal('CONTRACT_EDIT');
        setLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    socketContext.socket.on('chat message', msg => {
      if (!loading && msg?.message?.includes('[PREDEFINED]')) {
        fetchContract();
      }
    });
  }, [fetchContract, loading]);

  if (contractId && _.isEmpty(contractDetails) && !loading) {
    setLoading(true);
    fetchContract();
  }

  return {
    fetchContract,
    createContract,
    contractDetails,
    updatedContractId,
    loading,
  };
}
