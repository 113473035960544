import axios from 'axios';
import { useContext, useState } from 'react';
import { fromApi } from '../../common/models/user';
import { AuthContext } from '../../app/AuthContext';

function useFetchUsersFromSearch() {
  const { authContext } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const fetchSearchedUsers = search => {
    if (!search) {
      setSearchedUsers([]);
      setLoaded(true);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API_URL}/users/?search=${search}`,
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              authorization: `Bearer ${authContext.token}`,
            },
          },
        )
        .then(response => {
          setSearchedUsers(response.data.map(fromApi));
          setLoaded(true);
          setLoading(false);
          return response.data;
        })
        .catch(error => {
          if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
          setLoaded(true);
          setLoading(false);
        });
    }
  };

  return { searchedUsers, loading, loaded, fetchSearchedUsers };
}

export default useFetchUsersFromSearch;
