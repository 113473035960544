import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FullscreenLoader } from '../common/FullscreenLoader';

const Login = ({}) => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <FullscreenLoader />;
};

export default Login;
