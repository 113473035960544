import React from 'react';
import styled from 'styled-components';

import HomeLogo from '../../common/HomeLogo';
import { NavBar } from '../../common/NavBar';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  min-height: 100px;
  grid-template-columns: 1fr 1.2fr 1fr 0.6fr 1.2fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: 'Logo Navigation Navigation Navigation Navigation';
`;

const HeaderContainer = styled.div`
  min-height: 100px;
`;

const Logo = styled.div`
  grid-area: Logo;
  display: flex;
  align-items: center;
  padding: 0 4rem;
  padding-top: 24px;
  ${({ hasLeftPanel }) => hasLeftPanel && 'background-color: #f4f5f4;'}
  min-width: 320px;
`;

const Navigation = styled.div`
  grid-area: Navigation;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 5rem;
  padding-right: 4rem;
`;

const Section = styled.div`
  min-height: 50px;
  max-width: 400px;
  width: 100%;
  ${({ type }) =>
    type === 'view' &&
    `
    margin: 0 auto;
    max-width: 640px;
  `}
`;

const Form = styled.div`
  width: 100%;
  display: grid;
  min-height: calc(100vh - 100px);
  position: relative;
  grid-template-columns: 1fr 1.2fr 1fr 0.6fr 1.2fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: '${({ hasLeftPanel }) =>
    hasLeftPanel
      ? 'LeftPanel'
      : 'MiddlePanel'} MiddlePanel MiddlePanel MiddlePanel MiddlePanel';
`;

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  padding: 0 4rem;
  padding-bottom: 100px;
  background-color: #f4f5f4;
  min-width: 320px;
`;

const MiddlePanel = styled.div`
  grid-area: MiddlePanel;
  padding: 0 4rem;
  padding-bottom: 100px;
`;

const DrawerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function PageLayout({ leftPanel, children }) {
  return (
    <Container>
      <HeaderContainer>
        <Header>
          <Logo hasLeftPanel={!!leftPanel}>
            <Section>
              <HomeLogo position="absolute" />
            </Section>
          </Logo>
          <Navigation>
            <Section>
              <NavBar condense={true} />
            </Section>
          </Navigation>
        </Header>
      </HeaderContainer>
      <Form hasLeftPanel={!!leftPanel}>
        {!!leftPanel && (
          <LeftPanel>
            <DrawerContainer>{leftPanel}</DrawerContainer>
          </LeftPanel>
        )}
        <MiddlePanel>{children}</MiddlePanel>
      </Form>
    </Container>
  );
}
