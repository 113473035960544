import { useContext, useState } from 'react';
import { AuthContext } from '../app/AuthContext';
import axios from 'axios';
import { fromApi } from '../common/models/user';

export function useGetSearchedUsers() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;

  const getSuggestedUsers = value => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/users?search=${value}`, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setSearchedUsers((response.data ?? []).map(fromApi));
        setLoaded(true);
        setLoading(false);
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  const onSearchQuery = value => {
    if (value) {
      setLoading(true);
      getSuggestedUsers(value);
    } else if (!value || value.length === 0) {
      setSearchedUsers([]);
      setLoaded(false);
    }
  };

  return { searchedUsers, onSearchQuery, loaded, loading };
}
