import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import { Button } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButtonContainer = styled.div`
  text-align: left;
  position: relative;
  bottom: 100px;
  margin-top: 48px;

  button.MuiButtonBase-root {
    color: ${THEME_COLORS.primary};
    font-size: 1rem;
    text-transform: capitalize;
    justify-content: flex-end !important;
    padding-right: 0;
    svg {
      fill: ${THEME_COLORS.primary} !important;
    }
    &:hover {
      color: ${THEME_COLORS.primaryHover};
      background-color: unset;
    }
  }
`;

export const handleLogout = (history, logout) => e => {
  e.preventDefault();
  logout();
  localStorage.removeItem('token');
  localStorage.removeItem('dismissedAutoHowToPopup');
  localStorage.removeItem('skipGoalForNow');
  window.location = '/?message=logout_success';
};

export default function LogoutButton({ history }) {
  const { logout } = useAuth0();
  return (
    <LogoutButtonContainer>
      <Button
        startIcon={<LogoutIcon />}
        onClick={handleLogout(history, logout)}
      >
        Logout
      </Button>
    </LogoutButtonContainer>
  );
}
