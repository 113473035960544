const checkValidations = (validations, setErrors, errors) => inputs => {
  const errorFields = Object.entries(inputs)
    .filter(([key]) => Object.keys(errors).includes(key))
    .reduce((err, entries) => {
      err[entries[0]] = validations[entries[0]](entries);
      return err;
    }, {});
  setErrors(errorFields);
  return !Object.values(errorFields).some(v => v);
};

export default checkValidations;
