import * as ContractUser from './contractUser';
import moment from 'moment';

function fromApi(data) {
  return {
    chatId: data.chat_id,
    serialId: data.serial_id,
    status: data.status,
    modifiedAt: data.modified_at,
    modifiedAtUnix: data.modified_at ? moment(data.modified_at).unix() : -1,
    requesterUserContract: ContractUser.fromApi(
      data.requester_user_contract[0],
    ),
    requestedUserContract: ContractUser.fromApi(
      data.requested_user_contract[0],
    ),
  };
}

export { fromApi };
