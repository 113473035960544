import React from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import {
  Subheader18,
  Subheader14,
  Header12,
  Body12,
} from '../common/Typography';
import { CircularProgress } from '@mui/material';
import { SkipButton } from './SkipButton';
import { THEME_COLORS } from '../common/colors';

const Header = styledMui(Subheader18)(() => ({
  '&.MuiTypography-root': {
    textAlign: 'center',
    display: 'block',
    margin: '24px 0 16px',
  },
}));

const Subheader = styledMui(Subheader14)(() => ({
  '&.MuiTypography-root': {
    textAlign: 'center',
    display: 'block',
    color: THEME_COLORS.primary,
    marginBottom: '24px',
  },
}));

const Hint = styledMui(Header12)(() => ({
  '&.MuiTypography-root': {
    textAlign: 'center',
    display: 'block',
    marginBottom: '12px',
  },
}));

const Footer = styledMui(Body12)(() => ({
  '&.MuiTypography-root': {
    textAlign: 'center',
    display: 'block',
    color: THEME_COLORS.primary,
    margin: '12px 0',
  },
}));

const Container = styled.div`
  min-height: 100vh;
  height: fit-content;
  background: url('https://weswapp.s3.us-east-2.amazonaws.com/public/login_background.svg')
    50%;
  background-size: cover;
  --page-background-alignment: right;
`;

const Logo = styled.img`
  height: 68px;
  display: block;
  object-fit: contain;
  max-width: 100%;
  margin: 0 auto;
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-rows: [left-start center-start right-start] 1fr [left-end center-end right-end];
  grid-template-columns: [left-start center-start] 1fr [left-end right-start] 1fr [center-end right-end];
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-column-gap: 160px;
  column-gap: 160px;
  width: 100%;
  padding: 80px;
  min-height: 100vh;
  height: fit-content;

  @media (max-width: 600px) {
    padding: 0;
    margin: 0;
    justify-content: center;
  }
`;

const FlowForm = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  white-space: normal;
  background-color: #ffffff;
  position: relative;
  grid-area: right;
  width: 400px;
  border-radius: 5px;
  padding: 40px;

  font-size: 14px;
  color: #2d333a;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border: 0 solid transparent;

  @media screen and (max-width: 600px) and (min-width: 500px) {
    width: 400px;
    min-height: 100%;
    border-radius: initial;
    box-shadow: none;
    grid-area: center;
  }

  @media (max-width: 600px) {
    width: 100vw;
    min-height: 100%;
    border-radius: initial;
    box-shadow: none;
    grid-area: center;
    display: flex;
    flex-direction: column;
    height: auto;
    white-space: normal;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 540px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 340px;
`;

export function PageFlowLayout({
  header,
  subheader,
  children,
  loading,
  footer,
  hint,
  onSkip,
}) {
  return (
    <Container>
      <PageContainer>
        <FlowForm>
          <FormContainer>
            <Logo
              id="prompt-logo-center"
              src="https://weswapp.s3.us-east-2.amazonaws.com/public/WesuapLogo.svg"
              alt="WeSuap"
            />
            {loading && (
              <LoadingContainer>
                <CircularProgress color="inherit" size="5rem" />
              </LoadingContainer>
            )}
            {!loading && (
              <>
                <Header>{header}</Header>
                <Subheader>{subheader}</Subheader>
                {hint && <Hint>{hint}</Hint>}
                {children}
                {footer && <Footer>{footer}</Footer>}
                <SkipButton onSkip={onSkip} />
              </>
            )}
          </FormContainer>
        </FlowForm>
      </PageContainer>
    </Container>
  );
}
